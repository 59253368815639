import React, { useCallback, useEffect } from 'react'
import ReactTable from '../../../components/Table/ReactTable'
import WithDynamicPlugins from '../../../components/Table/WithPlugins'
import "../Reporting.css"
import { DAILY_SUMMARY_TABLE_HEADERS } from './DailySummaryConstants'
import ColumnFilter from '../../UserManagement/ColumnFilter'
import { levelFiltersLabelFormatter } from '../../../utils/filterLevelMapping'
import { numberUSFormatting } from '../../../utils/formatters/valueFormatters'
import "./DailySummary.css"
import NumberRangeColumnFilter from '../../../components/Filters/NumberRangeColumnFilter'

const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

const StyleColorTable = (props) => {

    const { data, getDetails, tableId, dc } = props

    const deepDiveTableColumns = [
        {
            Header: " ",
            sticky: "left",
            columns: [{
            Header: ' ',
            sticky: "left",
            columns: [
                {
                    Header: DAILY_SUMMARY_TABLE_HEADERS.ARTICLE_ID,
                    accessor: "article",
                    // Filter: ColumnFilter,
                    Filter: (instance) => <ColumnFilter {...instance} placeholder="Search in bulk by ','" />,
                    filter: 'bulkFilterCommaSeperated',
                    Cell: (instance) => (
                        <div className="clickable" onClick={() => getDetails(instance.row?.original)}>{instance.row?.original?.article}</div>
                    ),
                },
                {
                    Header: DAILY_SUMMARY_TABLE_HEADERS.STYLE_ID,
                    accessor: 'style_code',
                    Filter: (instance) => <ColumnFilter {...instance} placeholder="Search in bulk..." />,
                    filter: 'bulkFilterSpaceSeperated',
                },
                {
                    Header: DAILY_SUMMARY_TABLE_HEADERS.COLOR_ID,
                    accessor: 'color_code',
                    Filter: (instance) => <ColumnFilter {...instance} placeholder="Search in bulk..." />,
                    filter: 'bulkFilterSpaceSeperated',
                },
            ]
        }]
        },
        {
            Header: DAILY_SUMMARY_TABLE_HEADERS.STYLE_DESCRIPTION,
            accessor: "style_name",
            width: 300,
            Filter: ColumnFilter,
        },
        {
            Header: DAILY_SUMMARY_TABLE_HEADERS.STYLE_COLOR,
            accessor: "color_desc",
            width: 200,
            Filter: ColumnFilter,

        },
        {
            Header: DAILY_SUMMARY_TABLE_HEADERS.COLOR,
            accessor: "color",
            Filter: ColumnFilter,
        },
        {
            Header: "  ",
            identify: "dcSplitParent",
            columns: [
                {
                    Header: levelFiltersLabelFormatter("level1"),
                    accessor: "l1_name",
                    disableFilters: true
                },
                {
                    Header: levelFiltersLabelFormatter("level2"),
                    accessor: "l2_name",
                    disableFilters: true,
                },
                {
                    Header: levelFiltersLabelFormatter("level3"),
                    accessor: "l3_name",
                    disableFilters: true,
                },
                {
                    Header: levelFiltersLabelFormatter("level4"),
                    accessor: "l4_name",
                    disableFilters: true,
                },
                {
                    Header: levelFiltersLabelFormatter("level5"),
                    width: 200,
                    accessor: "l5_name",
                    Filter: ColumnFilter,
                },
                {
                    Header: levelFiltersLabelFormatter("level6"),
                    accessor: "l6_name",
                    Filter: ColumnFilter,
                },
                {
                    Header: DAILY_SUMMARY_TABLE_HEADERS.WIDTH,
                    accessor: "width",
                    Filter: ColumnFilter,
                },
                {
                    Header: DAILY_SUMMARY_TABLE_HEADERS.ALLOCATION_ID,
                    accessor: "plan_description",
                    Filter: ColumnFilter,
                    width: 350
                },
                {
                    Header: DAILY_SUMMARY_TABLE_HEADERS.RELEASED_AT,
                    accessor: "updated_at",
                    Filter: ColumnFilter,
                },
                {
                    Header: DAILY_SUMMARY_TABLE_HEADERS.UNITS_ALLOCATED,
                    identify: "dcSplitUnits",
                },
                {
                    Header: DAILY_SUMMARY_TABLE_HEADERS.DC_UNITS_OH,
                    identify: "dcAvailable"
                },
                // {
                //     Header: DAILY_SUMMARY_TABLE_HEADERS.RESERVE_QTY,
                //     accessor: "reserve_quantity",
                //     Cell: (inst) => numberUSFormatting(inst?.value),
                //     Filter: NumberRangeColumnFilter,
                //     filter: 'between',
                // },
            ],
        },
    ]

    const createDCColumns = () => {
        if(data?.length) {
            let dcSplitParentIndex = deepDiveTableColumns.findIndex(col => col.identify === "dcSplitParent")
            let dcSplitUnitsIndex = deepDiveTableColumns[dcSplitParentIndex].columns.findIndex(col => col.identify === "dcSplitUnits");
            let dcAvailableIndex = deepDiveTableColumns[dcSplitParentIndex].columns.findIndex(col => col.identify === "dcAvailable");

            deepDiveTableColumns[dcSplitParentIndex].columns[dcSplitUnitsIndex].columns = []
            deepDiveTableColumns[dcSplitParentIndex].columns[dcAvailableIndex].columns = []


            dc?.dcList?.map(dcKey => {
                deepDiveTableColumns[dcSplitParentIndex].columns[dcSplitUnitsIndex].columns.push({
                    Header: dc.dc_map[dcKey],
                    id: dcKey + dcSplitUnitsIndex,
                    accessor: row => row.units_allocated_dc?.[dcKey],
                    Cell: (inst) => numberUSFormatting(inst?.row.original?.units_allocated_dc?.[dcKey]),
                    Filter: NumberRangeColumnFilter,
                    filter: 'between',
                });

                deepDiveTableColumns[dcSplitParentIndex].columns[dcAvailableIndex].columns.push({
                    Header: dc.dc_map[dcKey],
                    id: dcKey + dcAvailableIndex,
                    accessor: row => row.dc_available_split?.[dcKey],
                    Cell: (inst) => numberUSFormatting(inst?.row.original?.dc_available_split?.[dcKey]),
                    Filter: NumberRangeColumnFilter,
                    filter: 'between',
                });
            })
            deepDiveTableColumns[dcSplitParentIndex].columns[dcSplitUnitsIndex].columns.push({
                Header: DAILY_SUMMARY_TABLE_HEADERS.TOTAL,
                accessor: "units_allocated",
                Cell: (inst) => numberUSFormatting(inst?.value),
                Filter: NumberRangeColumnFilter,
                filter: 'between',
            });

            deepDiveTableColumns[dcSplitParentIndex].columns[dcAvailableIndex].columns.push({
                Header: DAILY_SUMMARY_TABLE_HEADERS.TOTAL,
                accessor: "dc_available",
                Cell: (inst) => numberUSFormatting(inst?.value),
                Filter: NumberRangeColumnFilter,
                filter: 'between',
            });
            return true;
        }
        return false;
    }
    return (
        createDCColumns() &&
        <ReactTableWithPlugins
            columns={deepDiveTableColumns}
            data={data ? data : []}
            renderMarkup='TableMarkup'
            keyRT="sortAndSearch"
            shouldPagination
            totalRecordsLen={data?.length}
            tableId={tableId}
            tableWrapperStyle={{ height: 'fit-content', maxHeight: '50rem' }}
            headerWrapperStyle={{ position: 'sticky', top: 0, zIndex: '4' }}
        />
    )
}

export default StyleColorTable