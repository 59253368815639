import { isEmpty, pickBy } from "lodash";
import { takeLatest, put, all, call, takeEvery } from "redux-saga/effects";
import { colors } from "../../constants/colorConstants";
import {
  GET_CHART_DATA,
  GET_STYLE_INV_POPUP_DATA,
  GET_REVIEW_RECOMMEND_DATA,
  getChartDataSuccess,
  getStyleInventoryDataSuccess,
  getStyleInventoryDataError,
  getChartDataError,
  FETCH_FILTER_DATA,
  fetchFilterDataError,
  fetchFilterDataSuccesss,
  getStyleInvPopupDataSuccess,
  getStyleInvPopupDataError,

  getReviewRecommendDataSuccess,
  getReviewRecommendDataError,
  getReviewRecommendDataOnProgress,
  GET_TRANSACTION_DATA,
  getTransactionDetailsError,
  getTransactionDetailsSuccess,
  GET_RECOMMENDED_ACTIONS_DATA,
  getRecommendedActionstDataSuccess,
  getRecommendedActionstDataError,
  GET_STYLE_INVENTORY_DATA,
  DOWNLOAD_EXCEL_DATA,
  GET_DOWNLOAD_EXCEL_DATA_SUCCESS,
  GET_DOWNLOAD_EXCEL_DATA_ERROR,
  getCheckAllDataSuccess,
  CREATE_ALLOCATION,
  createAllocationSuccess,
  createAllocationError,
  UPDATE_STORE_S2S,
  UpdateStoreS2SSuccess,
  UpdateStoreS2SError,
  getCheckAllDataSuccessS2S,
} from "./DashboardActions";

import {
  getDashboardFilterData,
  getChartsData,
  getStyleInventoryData,
  getStyleInvPopupData,
  getReviewRecommendData,
  getTransactionDetails,
  getRecommendeActionsData,
  getStoreTransferReviewRecommend,
  getUpdatedStoreDashboardS2S
} from "../../routes/api";
import {createAllocationStoreToStore} from "../../routes/apis"
import { poAllocationSaga } from "./POAllocation/POAllocationSaga";
import moment from "moment";
import { cloneDeep } from "lodash";
import { flattenArray } from "../../utils/commonUtilities";
import { ERROR_MSG } from "../../constants/globalConstant";
import { formatStoreTransferReviewData } from "./RecommendedAction/StoreTransferReview/helperFunctions";

function* getChartDataWorker(action) {
  try {
    const { payload } = action;
    let req = {};
    if (!isEmpty(payload)) {
      for (const key in payload) {
        req[key] = key == "start_date" || key == "end_date" || key == "start_date_last_allocated" || key == "end_date_last_allocated" ? payload[key][0] :( payload[key].map(function mapper(ele) {
          if (Array.isArray(ele)) {
            return ele.map(mapper);
          } else {
            return ele.value;
          }
        }));
      }
      for (let i in req) {
        if (Array.isArray(req[i])) {
        req[i] = req[i].flat(Infinity);
        }
      }

    }
    else {
      // req = {level1: ["Accessories"]}
    }

    const res = yield call(getChartsData, req);

    if (res.data.status) {
      const weekSupply = res.data.wosData;
      const storeStock = res.data.storeStockData;
      const overallStock = res.data.overallStockData;

      const getPieChartColors = (p_labels) => {
        let l_colors = [];
        p_labels.forEach((element) => {
          l_colors.push(colors[element]);
        });
        return l_colors;
      };

      weekSupply.datasets.forEach((element) => {
        element.backgroundColor = colors[element.label];
      });

      storeStock.datasets.forEach((element) => {
        element.backgroundColor = colors[element.label];
        element.type = "horizontalBar";
      });

      overallStock.forEach((element) => {
        element.datasets[0].backgroundColor = getPieChartColors(element.labels);
      });

      let pieLegends = overallStock[0]?.labels?.map((val) => {
        return {
          text: val,
          backgroundColor: colors[val],
        };
      });

      yield put(
        getChartDataSuccess({
          weekSupply,
          overallStock,
          storeStock,
          pieLegends,
          styleInventoryData: res?.data?.styleInventoryData,
        })
      );
    } else {
      yield put(getChartDataError({ error: ERROR_MSG }));
    }
  } catch (error) {
    yield put(getChartDataError({ error: ERROR_MSG }));
  }
}

function* getStyleInventoryDataWorker(action){
  try{
    const { payload } = action;
    let req = {};
    if (!isEmpty(payload)) {
      for (const key in payload) {
        if(!["row_index", "searchColumns", "sortColumn", "excelCall", "row_count", "checkAll", "storesFilter", "reviewCall"].includes(key)) {
          req[key] = key == "start_date" || key == "end_date" || key == "start_date_last_allocated" || key == "end_date_last_allocated" ? payload[key][0] : (flattenArray(payload[key]).map(function mapper(ele) {
            if (Array.isArray(ele)) {
              return ele.map(mapper);
            } else {
              return ele?.value || ele;
            }
          }));
        }
        else {
          req[key] = payload[key]
        }
      }
      for (let i in req) {
        if (Array.isArray(req[i])) {
          req[i] = req[i].flat(Infinity);
          }
      }

    }
    else {
      // req = {level1: ["Accessories"]}
    }
    const res = yield call(payload["reviewCall"] ? getReviewRecommendData : getStyleInventoryData, req);
    if(res?.data?.status){
      let styleInventoryData = cloneDeep(payload["reviewCall"] ? res?.data?.recommendData : res?.data?.styleInventoryData)
      let storeCodes = res?.data?.store_code
        ? res?.data?.store_code.map((element) => ({
            value: element,
            label: element,
          }))
        : [];
      styleInventoryData.forEach(plan => {
          let timeStamp = moment(plan.intro_date)
          plan.intro_date = plan?.intro_date!==null ? moment(plan.intro_date).format("MM-DD-YYYY"):null
          // if(plan?.released_date) {
          //     let releasedTimeStamp = moment(plan.released_date)
          //     plan.released_date_picked = dateFormat(plan.released_date)
          //     plan.released_time = releasedTimeStamp.format('HH:mm:ss')
          // }
      })
      let dataObj = {
        data: styleInventoryData,
        totalCount: res.data.totalCount, nextIndex: res.data.row_index,
        storeCodes: storeCodes,
      }
      yield put(payload.checkAll ? getCheckAllDataSuccess(dataObj): getStyleInventoryDataSuccess(dataObj));
    }
    else{
      yield put(getStyleInventoryDataError({ error: ERROR_MSG }));
    }

  }
  catch(error){
    yield put(getStyleInventoryDataError({ error: ERROR_MSG }));
  }
}

function* getRecommendedActionsWorker(action) {
  try {
    const { payload } = action;
    let req = {};
    if (!isEmpty(payload)) {
      for (const key in payload) {
        req[key] = key == "start_date" || key == "end_date" || key == "start_date_last_allocated" || key == "end_date_last_allocated" ? payload[key][0] : (payload[key].map(function mapper(ele) {
          if (Array.isArray(ele)) {
            return ele.map(mapper);
          } else {
            return ele.value;
          }
        }));
      }
      for (let i in req) {
        if (Array.isArray(req[i])) {
          req[i] = req[i].flat(Infinity);
          }
      }
    }
    else {
      // req = {level1: ["Accessories"]}
    }

    const res = yield call(getRecommendeActionsData, req);

    if (res.data.status) {
      yield put(
        getRecommendedActionstDataSuccess({
          recommendedData: res.data.recommendedData,
        })
      );
    } else {
      yield put(getRecommendedActionstDataError({ error: ERROR_MSG }));
    }
  } catch (error) {
    yield put(getRecommendedActionstDataError({ error: ERROR_MSG }));
  }
}

function* fetchFilterDataWorker(action) {
  try {
    const { payload, filterType } = action;
    let req = {};
    let l_req = {};

    if (payload['filter_type'] && filterType["key"] === 'dc') {
      l_req = payload;
    } else if (!isEmpty(payload)) {
      for (const key in payload) {
        if (!isEmpty(payload?.[key]?.[0])) {
          req[key] = payload[key].map(function mapper(ele) {
            if (Array.isArray(ele)) {
              return ele.map(mapper);
            } else {
              return ele.value;
            }
          })
        };
      }
      for (let i in req) {
        req[i] = req[i].flat(Infinity);
      }
      l_req = pickBy(req, (value) => value.length > 0);
    }

    const res = yield call(getDashboardFilterData, l_req);

    if (res.data.status) {
      const data = {};
      data["filterMapping"] = res.data.filterMapping;
      if (filterType["key"] === 'dc') {
        data["data"] = res.data.data.map((element) => ({
          value: element['store_code'],
          label: element['store_name'],
        }));;
      } else {
        // let topObject = Object.keys(res.data.data[0])[0];
        for (const key in res.data.data[0]) {
          let k = "";
          if (key === "l1_name") {
            k = "level1Options";
          } else if (key === "l2_name") {
            k = "level2Options";
          } else if (key === "l3_name") {
            k = "level3Options";
          } else if (key === "l4_name") {
            k = "level4Options";
          } else if (key === "l5_name") {
            k = "level5Options";
          } else if (key === "l6_name") {
            k = "level6Options";
          } else if (key === "l7_name") {
            k = "level7Options";
          } else if (key === "style") {
            k = "styleIdOptions";
          } else if (key === "article") {
            k = "styleColorIdOptions";
          } else if (key === "dc") {
            k = "dcOptions";
          }else if (key === "factory_type") {
            k = "factoryTypeOptions";
          }else if (key === "lms_store_attribute") {
            k = "lmsStoreAttributeOptions";
          }else if (key === "lms_store_value") {
            k = "lmsStoreValueOptions";
          }else if (key === "width") {
            k = "widthOptions";
          }

          // if (key === "style" || key === "color") {
          //   data[k] = res.data.data.map((element) => ({
          //     value: element[key],
          //     label: element[key],
          //   }));
          // } else 
          if (key == "dc") {
            if (res.data.data[0][key]) {
              data["dcOptions"] = Object.entries(res.data.data[0][key])?.map(
                (element) => ({
                  value: element[0],
                  label: element[1],
                })
              );
            }
            // Do nothing for Ashley
          } else {
            let values;
            if (key === "style") {
              values = Object.values(res.data.data[0][key]);
            } else {
              values = res.data.data[0][key];
            }
            data[k] = values
              .filter((element) => element)
              .map((element) => ({
                value: element,
                label: element,
              }));
          }
        }
      }
      yield put(fetchFilterDataSuccesss({ data: data, key: action?.filterType?.key }));
    } else {
      yield put(fetchFilterDataError({ error: res.error }));
    }
  } catch (error) {
    yield put(fetchFilterDataError({ error: ERROR_MSG }));
  }
}

function* getStyleInvPopupDataWorker(action) {
  try {
    let req_payload = {}
    for (var key in action.payload) {
      if(Array.isArray(action.payload[key])){
        req_payload[key] = flattenArray(action.payload[key])?.map((ele) => ele?.value || ele)
      }
      else {
        req_payload[key] = action.payload[key]
      }
    }
    const res = yield call(getStyleInvPopupData, req_payload);
    if (res.data.status) {
      yield put(
        getStyleInvPopupDataSuccess({ data: res.data.styleInventoryPopUpData })
      );
    } else {
      yield put(getStyleInvPopupDataError({ error: ERROR_MSG }));
    }
  } catch (error) {
    yield put(getStyleInvPopupDataError({ error: ERROR_MSG }));
  }
}


function* getReviewRecommendDataWorker(action) {
  try {
    yield put(getReviewRecommendDataOnProgress({}))
    let req = action.payload
    const res = yield call(req?.store_transfer ? getStoreTransferReviewRecommend : getReviewRecommendData, req);
    if (res.data.status) {
      let recommendData = cloneDeep(res?.data?.recommendData)
      req?.store_transfer && (recommendData = formatStoreTransferReviewData(recommendData,res?.data?.transfer_strategy_options));
      recommendData.forEach(plan => {
          let timeStamp = moment(plan.intro_date)
          plan.intro_date = plan?.intro_date!==null ? moment(plan.intro_date).format("MM-DD-YYYY"):null
          // if(plan?.released_date) {
          //     let releasedTimeStamp = moment(plan.released_date)
          //     plan.released_date_picked = dateFormat(plan.released_date)
          //     plan.released_time = releasedTimeStamp.format('HH:mm:ss')
          // }
      })
      let dataObj = { data: recommendData, out_of_data: res?.data?.out_of_data , nextIndex: res.data.row_index ,transferStrategyOptions:res?.data?.transfer_strategy_options}
      yield put(req.checkAll ? getCheckAllDataSuccessS2S(dataObj): getReviewRecommendDataSuccess(dataObj) )
    } else {
      yield put(getReviewRecommendDataError({ error: "Something went wrong" }));
    }

  } catch (error) {
    yield put(getReviewRecommendDataError({ error: "Something went wrong" }))
  }
}

function* getTransactionDetailsWorker(){
  try {
    const res = yield call(getTransactionDetails);
    if (res.data.status) {
      yield put(getTransactionDetailsSuccess({ data: res.data.data }))
    } else {
      yield put(getTransactionDetailsError({ error: "Something went wrong" }));
    }

  } catch (error) {
    yield put(getTransactionDetailsError({ error: "Something went wrong" }))
  }
}

function* downloadStyleInvExcelWorker(action){
  try{
    const { payload } = action;
    let req = {};
    if (!isEmpty(payload) && !payload.reviewCall) {
      for (const key in payload) {
        if(!["row_index", "searchColumns", "sortColumn", "excelCall", "row_count", "reviewCall", "storesFilter"].includes(key)) {
          req[key] = key == "start_date" || key == "end_date" || key == "start_date_last_allocated" || key == "end_date_last_allocated" ? payload[key]?.[0] : (payload[key]?.map(function mapper(ele) {
            if (Array.isArray(ele)) {
              return ele.map(mapper);
            } else {
              return ele.value;
            }
          }));
        }
        else {
          req[key] = payload[key]
        }
      }
      for (let i in req) {
        if (Array.isArray(req[i])) {
          req[i] = req[i].flat(Infinity);
          }
      }

    }
    else {
      req = {...payload}
    }
    delete req?.reviewCall
    const res = yield call( payload.reviewCall ? getReviewRecommendData : getStyleInventoryData, req);
    if(res?.data?.status){
      let styleInventoryData = cloneDeep(res?.data?.styleInventoryData || res?.data?.recommendData)
      styleInventoryData.forEach(plan => {
          let timeStamp = moment(plan.intro_date)
          plan.intro_date = plan?.intro_date!==null ? moment(plan.intro_date).format("MM-DD-YYYY"):null
          // if(plan?.released_date) {
          //     let releasedTimeStamp = moment(plan.released_date)
          //     plan.released_date_picked = dateFormat(plan.released_date)
          //     plan.released_time = releasedTimeStamp.format('HH:mm:ss')
          // }
      })
      let dataObj = {
        data: styleInventoryData,
        totalCount: res.data.totalCount, nextIndex: payload.row_index + payload.row_count
      }
      yield put({type: GET_DOWNLOAD_EXCEL_DATA_SUCCESS, ...dataObj});
    }
    else{
      yield put({type: GET_DOWNLOAD_EXCEL_DATA_ERROR, error: "Error in download!!"});
    }

  }
  catch(error){
    console.log("downloaderror", error)
    yield put({type: GET_DOWNLOAD_EXCEL_DATA_ERROR, error: "Error in download!!"});
  }
}

function* createAllocationWorker(action) {
  try {
    let {payload, isSSE} = action
    console.log(isSSE,'gettingenvId')
    let apiEndPoint;
    let req = {};
    // for(let i in payload) {
    //   if(payload[i]) {
    //     req = [payload[i]]
    //   }
    // }
    if(isSSE) {
      apiEndPoint = createAllocationStoreToStore
      req = {"input" : payload}
    }
    console.log(apiEndPoint,req,'gettingenvId')
    const res = yield call(apiEndPoint, req);
    if (res.data.status) {
      let res_data = res.data?.uuid_str ? res.data?.uuid_str : res.data?.allocation_code
      yield put(createAllocationSuccess({ data: res_data }));
    } else {
      yield put(createAllocationError({ error: res.data }));
    }
  } catch (error) {
    yield put(createAllocationError({ error: ERROR_MSG }));
  }
}

function* updateStoreDashboardS2SWorker(action) {
  try {
    let { payload } = action;
    const res = yield call(getUpdatedStoreDashboardS2S, payload);
    if (res?.data?.status) {
      yield put(UpdateStoreS2SSuccess({ newStoreData: res?.data?.source_destinations[0].source_destination }));
    } else {
      yield put(UpdateStoreS2SError({}));
    }
  } catch (error) {
    yield put(UpdateStoreS2SError({}));
  }
}

function* getChartDataWatcher() {
  yield takeLatest(GET_CHART_DATA, getChartDataWorker);
}

function* getStyleInventoryDataWatcher() {
  yield takeLatest(GET_STYLE_INVENTORY_DATA, getStyleInventoryDataWorker);
}

function* getRecommendedActionsWatcher() {
  yield takeLatest(GET_RECOMMENDED_ACTIONS_DATA, getRecommendedActionsWorker);
}

function* fetchFilterDataWatcher() {
  yield takeEvery(FETCH_FILTER_DATA, fetchFilterDataWorker);
}

function* getStyleInvPopupDataWatcher() {
  yield takeLatest(GET_STYLE_INV_POPUP_DATA, getStyleInvPopupDataWorker);
}

function* getReviewRecommendDataWatcher() {
  yield takeLatest(GET_REVIEW_RECOMMEND_DATA, getReviewRecommendDataWorker)
}

function* downloadStyleInvExcelWatcher() {
  yield takeLatest(DOWNLOAD_EXCEL_DATA, downloadStyleInvExcelWorker)
}

function* getTransactionDetailsWathcer() {
  yield takeLatest(GET_TRANSACTION_DATA,getTransactionDetailsWorker)
}

function* createAllocationWatcher() {
  yield takeLatest(
    CREATE_ALLOCATION,
    createAllocationWorker
  );
}

function* updateStoreDashboardS2SWatcher() {
  yield takeLatest(
    UPDATE_STORE_S2S,
    updateStoreDashboardS2SWorker
  );
}

export function* dashboardSaga() {
  yield all([
    getChartDataWatcher(),
    getStyleInventoryDataWatcher(),
    getRecommendedActionsWatcher(),
    getStyleInvPopupDataWatcher(),
    fetchFilterDataWatcher(),
    getReviewRecommendDataWatcher(),
    getTransactionDetailsWathcer(),
    poAllocationSaga(),
    downloadStyleInvExcelWatcher(),
    createAllocationWatcher(),
    updateStoreDashboardS2SWatcher(),
  ]);
}
